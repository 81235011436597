@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  &-service-seat {
    background-color: $color-grey-30;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--stairs {
      background-color: unset;
    }
  }

  &__labeled-seat[alt='ES'] {
    width: 50px;
    height: 100px;

    &.seat-selection__labeled-seat--up {
      transform: rotate(270deg);
      transform-origin: 28% 65%;
    }

    &.seat-selection__labeled-seat--right {
      transform: translateY(-20px);
    }
  }
}
