@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$cell-width: 44px;
$cell-height: 46px;
$cell-gap: 16px;
$h-padding: 20px;
$border: 1px;
$add-padding: 2px;
$deck-min-width: 922px;

@function calc-grid-size($items) {
  @return $cell-width * $items + $cell-gap * ($items - 1) + $h-padding * 2 + $border * 2;
}

$deck-width: calc-grid-size(5);
$deck-height: calc-grid-size(15);

.seat-selection__deck-wrapper {
  --individual-rotation: 0deg;
  --deck-rotation: 0deg;

  overflow: auto;
  padding: 0 2px;
  display: flex;
  position: relative;

  .seat-selection__deck {
    position: relative;
    background: $color-seat-selection-bg;
    border: $border solid $color-seat-selection-border;
    box-shadow: 0 0 12px rgb(11 8 39 / 8%);
    border-radius: 40px;
    display: grid;
    gap: $cell-gap;
  }

  &--up {
    height: fit-content;
    justify-content: center;

    .seat-selection__deck {
      width: $deck-width;
      height: max-content;
      min-height: $deck-height;
      padding: 90px $h-padding 40px;
      grid-auto-columns: minmax($cell-width, 0);
      grid-auto-rows: minmax($cell-height, 0);
    }

    .seat-selection__seat {
      & .font-icon-checkout {
        @include on-desktop {
          top: calc(30% - 10px);
          left: calc(50% - 11px);
        }
      }
    }
  }

  &--right {
    --deck-rotation: 90deg;

    direction: rtl;
    min-width: $deck-height;

    .seat-selection__deck {
      height: $deck-width;
      width: max-content;
      min-width: $deck-min-width;
      padding: $h-padding 90px $h-padding 40px;
      grid-auto-rows: minmax($cell-width, 0);
      grid-auto-columns: minmax($cell-height, 0);
    }
  }

  &--head {
    .seat-selection__deck {
      padding: 145px 20px 40px;
      border-radius: 140px 140px 40px 40px;
    }
  }

  .deck-rotate {
    rotate: calc(var(--deck-rotation) + var(--individual-rotation));
  }

  .deck-counter-rotate {
    rotate: calc(0deg - var(--deck-rotation) - var(--individual-rotation));
  }

  .seat-selection__extra-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
